import { useStore } from "./state.js";
import { useNavigate } from "react-router-dom";
import { format, minutesToHours, millisecondsToMinutes, parse } from 'date-fns';

export function setStorage(key, value) {
  if (value == 0)
    localStorage.removeItem(key);
  else
    localStorage.setItem(key, value);
}
export function getStorage(key) {
  let val = localStorage.getItem(key);
  return (val);
}



export function rupees(amt) {
  if (!amt) {
    amt = 0
  } else {
    amt = parseInt(amt)
  }
  return 'Rs.'+ amt.toLocaleString('en-IN', {

    currency: 'INR',
    currencyDisplay: "code",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

}

export function HourMin(amt) {
  return minutesToHours(amt) + ':' + (amt % 60);
}

export function validateForm(e) {
  var form = e.target;
  var invalidNode = form.querySelector(':invalid');
  if (invalidNode) invalidNode.focus();
  e.preventDefault()
  e.stopPropagation()
  form.classList.add('was-validated')

  return form.checkValidity();


}

export function LoginUser(token) {
  //useStore.setState({ auth: 1 });
  //setStorage("token", token);




}

export function Logout() {
  setStorage("token", '');
  setStorage("rtoken", '');
  useStore.setState({ auth: null });

}
