import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { LoadingScreen } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import {
  IoPricetagsOutline,
  IoChevronForwardOutline,
  IoCheckmarkCircle,
} from "react-icons/io5";
import { FaCartArrowDown } from "react-icons/fa";


export default function CheckoutPayment() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value




  var PlaceOrder = () => {

    var data = new FormData();
    data.append('selected', state.selected)

    instance({
      method: "post",
      url: "/payment?add",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {


        if (response.data.orderno) {
          useStore.setState({ cart_count: 0 });
          navigate('/order-success/' + response.data.orderno);

        }
        if (response.data.error) {
          toast('Something went wrong!', { type: "error" });
          navigate('/cart');
        }





      })
      .catch(function (response) { });
  };



  var onInput1 = (event) => {

    let val = event.target.value;
    setState({ ...state, selected: val });
  }



  const fetchData = () => {
    instance
      .get("/payment?list=1")
      .then(function (response) {

        setState({ ...state, ...response.data });
        if (response.data.error) {
          toast('Something went wrong!', { type: "error" });
          navigate('/cart', { replace: true });
        }
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';



  useEffect(() => {
    setTitle('Payment')
    fetchData()
  }, []);
  return (
    <div className="mt-2">
      <Helmet>
        <title>Checkout Payment</title>
      </Helmet>
      {state.pg ? (
        <>

          <div className="pt-2">


            <div >
              {state.pg.map((adata, index) => {
                return <div className="bg-white p-3 mb-2 rounded " key={index}>
                  <div className="d-flex">
                    <input type="radio" className="me-3" id={adata.id} value={adata.id} onChange={onInput1} checked={state.selected == adata.id ? true : false}></input>
                    <div>
                      <label htmlFor={adata.id}> <b>{adata.name}</b></label>

                    </div>
                  </div>



                </div>;
              })}
            </div>

            <div className="bottom-strip">
              <button className="btn btn-success btn-lg mt-4 w-100" onClick={() => PlaceOrder()} type="submit" disabled={state.selected ? false : true}>Continue</button>
            </div>

          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}
