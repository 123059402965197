import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate, NavLink, Link, Navigate } from "react-router-dom";
import { AiOutlineUser } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { instance } from "./axios";
import { useStore } from "./state.js";
import { AiOutlineHome, AiOutlineStar, AiOutlineShoppingCart, AiOutlineClose } from "react-icons/ai";

import { HiOutlineUserCircle } from "react-icons/hi";

import { IoMdArrowBack, IoMdCall } from "react-icons/io";
import { TbZoomCancel } from "react-icons/tb";
import { BsWhatsapp } from "react-icons/bs";

import zustand from "zustand";


const Layout = () => {
  const location = useLocation();
  const [state, setState] = useState({});
  const [squery, setQuery] = useState('');
  const [title, setTitle] = useState("");
  const zstate = useStore((state) => state.cart_count);
  const setZState = useStore((state) => state.setCount);
  const homeurl = 'https://jalorefooddelivery.com';
  const { auth, setAuth } = useStore((state) => state);
  let activeStyle = {
    textDecoration: "underline",
  };
  useEffect(() => {


    setState({ ...state, items: null })

  }, [location]);


  useEffect(() => {


    instance
      .get("/user")
      .then(function (response) {
        // setState({ ...state, ...response.data })
      })
      .catch(function (error) { })
      .then(function () { });

  }, []);



  let navigate = useNavigate();
  const searchInput = (e) => {

    var vall = e.target.value;
    console.log(vall)
    setQuery(vall)
    if (vall.length > 0) {
      instance
        .get("/search?q=" + vall)
        .then(function (response) {
          setState({ ...state, ...response.data })
        })
        .catch(function (error) { })
        .then(function () { });
    } else {
      setState({ ...state, items: null })
    }
  }


  const handleSClick = (url) => {
    setState({ ...state, items: null })
    navigate('/product/' + url)
  }
  return (
    <>
      <header className="navbar navbar-light sticky-top bg-light align-items-center d-flex shadow-sm py-2 px-3">
        {location.pathname !== '/' && (
          <Link className="p-2 fs-1 text-dark" to={-1}><IoMdArrowBack /></Link>
        )}
        <a className="navbar-brand me-0" href="#">
          <img className="logo" src="https://jalorefooddelivery.com/imgs/logo.svg" />
        </a>

        <div className="flex-grow-1 col-9 ps-3">
          {location.pathname == '/' ? (
            <div className="position-relative searchfield">
              <input style={{ height: '45px' }} className="form-control form-control-lg" placeholder="Search for anything" onChange={searchInput} value={squery}></input>
              {state.items && (
                <button className="btn btn-lg button text-dark" type="button" onClick={() => setState({ ...state, items: null, query: '' })}><AiOutlineClose /></button>
              )}
            </div>
          ) : (
            <h1 className="mb-0 fs-3">{title}</h1>
          )}
        </div>
      </header>

      {state.items && (
        <div className="searchresult">
          <div className="resultinner">
            {state.notfound && (
              <div className="text-center pt-4 mt-4 text-muted">
                <div style={{ 'font-size': '40px' }}><TbZoomCancel /></div>
                <b>No result found!</b>
              </div>
            )}
            {state.items.map((val, i) => (
              <a key={i} className="d-flex w-100 py-3 px-2 border-bottom align-items-center" onClick={() => handleSClick(val.url)}>
                <div className="catimg1 me-3">
                  <img className="w-100" src={`${homeurl}/imgs/product/s/${val.main_image}`} />
                </div>
                <div className="flex-grow-1 text-dark">
                  {val.title}
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">


          <main className="p-2">
            <section className="content d-inline-block w-100">
              <div className="container-fluid">
                <Outlet context={{ setTitle }} />
              </div>
            </section>
          </main>
          <a href="tel:+918233224773" className="callbtn fixbtn text-white">
            <IoMdCall />
          </a>
          <a href="https://wa.me/918233224773?text=Hi" target="_blank" className="fixbtn wpbtn text-white">
            <BsWhatsapp />
          </a>
          <div className="fixed-bottom bg-white border-top appbottom">
            <div className="row">
              <div className="col-3">
                <NavLink to="/">
                  <div><AiOutlineHome /></div>
                  Home
                </NavLink>
              </div>
              <div className="col-3">
                <NavLink to="/categories">
                  <div><AiOutlineStar /></div>
                  Categories
                </NavLink>
              </div>
              <div className="col-3">
                <NavLink to="/cart">
                  <div className="position-relative"><AiOutlineShoppingCart />{zstate > 0 && (<span className="cartcount">{zstate}</span>)}</div>
                  Cart
                </NavLink>
              </div>
              <div className="col-3">
                <NavLink to="/user-profile">
                  <div><HiOutlineUserCircle /></div>
                  Profile
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
