import React, { useState, useEffect, useCallback } from "react";

import { useNavigate, Link } from "react-router-dom";
import { instance } from "../../axios";
import { MdOutlineArrowForwardIos, MdOutlineMap } from "react-icons/md";
import { rupees } from "../../helper";

import Slider from "react-slick";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



export default function Home() {
  const homeurl = 'https://jalorefooddelivery.com';
  const [state, setState] = useState({});
  const [slidenum, setSlidenum] = useState(1);

  useEffect(() => {
    if (window.innerWidth > 500) {
      setSlidenum(2)
    }
    fetchData()

  }, []);


  const fetchData = () => {
    instance
    .get("/home")
    .then(function (response) {
      setState({ ...state, ...response.data })
    })
    .catch(function (error) { })
    .then(function () { });
  }

  var AddtoCart = (qty, id,i1,i) => {

    var data = new FormData();
    data.append('quantity', qty)
    data.append('id', id)
    instance({
      method: "post",
      url: "/cart?action=add",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          //toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          //fetchData()
          if(i1 => 0){
            //console.log(i1)
            var arr1 = state.category_products.slice()
          arr1[i1].items[i].quantity = qty
          setState({ ...state, category_products: arr1 })
          } else {
                    var arr = state.trending.slice()
          arr.filter(x => x.id == id)[0].quantity = qty

          setState({ ...state, trending: arr })
          }
        }
      })
      .catch(function (response) { });
  };




  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: slidenum,
    arrows: false,
    slidesToScroll: 1
  };

  var settings2 = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: slidenum * 2,
    arrows: false,
    slidesToScroll: 1
  };



  return (
    <div className="home">
      <Helmet>
        <title>Jalore Food Delivery</title>
      </Helmet>
      {state.category ? (
        <>
          <h2 className="fs-3 mb-4 mt-4">Categories</h2>
          <div className="row justify-content-md-center row-cols-auto">
            {state.category && state.category.map((val, i) => (
              <div className="col-3 col-sm text-center mb-3" key={i}>
                <Link className="text-dark" to={`/category/${val.url}`}>
                  <div className="catimg">
                    <LazyLoadImage effect="blur" src={homeurl + '/imgs/category/m/' + val.image} />
                  </div>
                  {val.title}
                </Link>
              </div>
            ))}

            <div className="col-3 col-sm text-center" >
              <Link className="text-dark" to="/categories">
                <div className="catimg d-flex align-items-center" style={{ backgroundColor: '#ddd' }}>
                  <div className="fs-1 w-100 text-muted">
                    <MdOutlineArrowForwardIos />
                  </div>
                </div>
                View All
              </Link>
            </div>
          </div>

          <h2 className="fs-3 mb-4 mt-4">Special Deals for You</h2>



          <Slider {...settings}>


            {state.slider && state.slider.map((val, i) => (

              <div className="position-relative px-2 overflow-hidden" key={i}>

                <div className="ratio ratio-16x9" >
                  <LazyLoadImage effect="blur" src={homeurl + '/imgs/slider/' + val.image} className="d-block w-100 rounded" />
                  <div className="slidertitle"><a className="text-white" href={val.url} >
                    {val.title}</a>
                  </div>

                </div>

              </div>

            ))}
          </Slider>
          {state.trending && state.trending.length > 0 && (
            <>
              <h2 className="fs-3 mb-4 mt-4">Trending Items</h2>
              <div className="row">
                {state.trending.map((val, i) => (
                  <div key={i} className="col-6 col-sm-3">
                    <div className="rounded p-2 bg-white mb-3 d-block">
                      <Link to={`/product/${val.url}`} >
                        <div className="ratio ratio-1x1">
                          <LazyLoadImage effect="blur" className="w-100"
                            src={`${homeurl}/imgs/product/s/${val.main_image}`} />
                        </div>
                      </Link>
                      <Link to={`/product/${val.url}`}>
                        <span className="d-block text-truncate text-dark py-3">{val.title}</span>
                      </Link>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <b className="d-block">{rupees(val.price)}</b>
                          {val.freeshipping && (
                            <small className="text-success fw-light">Free Delivery</small>
                          )}
                        </div>
                        {val.quantity && val.quantity > 0 ? (
                          <div>
                            <button className="btn btn-danger btn-sm" onClick={() => AddtoCart(val.quantity - 1, val.id)}>–</button>
                            <button className="btn btn-outline-secondary btn-sm mx-1" disabled>{val.quantity}</button>
                            <button className="btn btn-success btn-sm" onClick={() => AddtoCart(val.quantity + 1, val.id)}>+</button>
                          </div>
                        ) : (

                          <button className="btn btn-success" onClick={() => AddtoCart(1, val.id)}>Add</button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </>
          )}

          {state.category_products && state.category_products.length > 0 && (
            <>
        
              {state.category_products.map((val1,i1) => (
                <div key={i1}>
                
                <h2 className="fs-3 mb-4 mt-4">{val1.category}</h2>
              <div className="row">
                {val1.items.map((val, i) => (
                  <div key={i} className="col-6 col-sm-3">
                    <div className="rounded p-2 bg-white mb-3 d-block">
                      <Link to={`/product/${val.url}`} >
                        <div className="ratio ratio-1x1">
                          <LazyLoadImage effect="blur" className="w-100"
                            src={`${homeurl}/imgs/product/s/${val.main_image}`} />
                        </div>
                      </Link>
                      <Link to={`/product/${val.url}`}>
                        <span className="d-block text-truncate text-dark py-3">{val.title}</span>
                      </Link>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <b className="d-block">{rupees(val.price)}</b>
                          {val.freeshipping && (
                            <small className="text-success fw-light">Free Delivery</small>
                          )}
                        </div>
                        {val.quantity && val.quantity > 0 ? (
                          <div>
                            <button className="btn btn-danger btn-sm" onClick={() => AddtoCart(val.quantity - 1, val.id,i1, i)}>–</button>
                            <button className="btn btn-outline-secondary btn-sm mx-1" disabled>{val.quantity}</button>
                            <button className="btn btn-success btn-sm" onClick={() => AddtoCart(val.quantity + 1, val.id,i1, i)}>+</button>
                          </div>
                        ) : (

                          <button className="btn btn-success" onClick={() => AddtoCart(1, val.id, i1, i)}>Add</button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              </div>
                </div>
              ))}
            </>
          )}
          {state.upcoming && state.upcoming.length > 0 && (
            <>
              <h2 className="fs-3 mb-4 mt-4">Upcoming Items</h2>



              <Slider {...settings2}>


                {state.upcoming.map((val, i) => (

                  <div className="position-relative px-2 overflow-hidden" key={i}>

                    <div className="ratio ratio-1x1" >
                      <LazyLoadImage effect="blur" src={homeurl + '/imgs/slider/' + val.image} className="d-block w-100 rounded" />
                      <div className="slidertitle"><a className="text-white" href={val.url} >
                        {val.title}</a>
                      </div>

                    </div>

                  </div>

                ))}
              </Slider>
            </>
          )}

        </>
      ) : (

        <div className="placeholder-glow w-100 h-100 position-fixed top-0 start-0">
          <div className="w-100 h-100 placeholder" style={{ backgroundColor: 'rgb(171 171 171)' }}></div>
        </div>
      )}

    </div>
  );
}
