import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { LoadingScreen } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



import {
  IoPricetagsOutline,
  IoChevronForwardOutline,
  IoCheckmarkCircle,
} from "react-icons/io5";
import { FaCartArrowDown } from "react-icons/fa";


export default function Cart() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.cart_count);
  const setZState = useStore((state) => state.setCount);

  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value

  var AddtoCart = (qty, id) => {

    var data = new FormData();
    data.append('quantity', qty)
    data.append('id', id)
    instance({
      method: "post",
      url: "/cart?action=add",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          //toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          fetchData()
          // toast('Added to cart', { type: "success" });
        }
      })
      .catch(function (response) { });
  };



  var ApplyCoupon = () => {

    var data = new FormData();
    data.append('coupon', state.couponcode)

    instance({
      method: "post",
      url: "/coupon",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.remark) {
          toast(response.data.remark, { type: "info" });
        }

        fetchData()

      })
      .catch(function (response) { });
  };


  var RemoveCoupon = () => {

    var data = new FormData();

    instance({
      method: "post",
      url: "/cart?action=remove-coupon",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, couponcode: null });

        fetchData()

      })
      .catch(function (response) { });
  };



  const fetchData = () => {
    instance
      .get("/cart?action=list")
      .then(function (response) {

        setState({ ...state, ...response.data });
        setZState(response.data.cart_count)

      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';

  useEffect(() => {
    setTitle('Cart')
    fetchData()
  }, []);
  return (
    <div className="mt-3">
      <Helmet>
        <title>Cart</title>
      </Helmet>




      {state.cart_itms && state.cart_itms.length > 0 ? (
        <>       <div className="row">
          <div className="col-sm-6">
            {state.cart_itms.map((val, i) => (
              <div className="p-2 bg-white rounded mb-2" key={i}>

                <div className="d-flex align-items-center">
                  <div className="ratio ratio-1x1" style={{ width: '100px' }}>
                    <Link to={`/product/${val.url}`}>
                      <LazyLoadImage effect="blur" className="w-100 h-100 rounded" src={homeurl + '/imgs/product/s/' + val.main_image} />
                    </Link>
                  </div>

                  <div className="ms-3 w-100">
                    <Link to={`/product/${val.url}`}>
                      <h2 className="fs-5 text-dark mb-3">{val.title}</h2>
                    </Link>
                    <div className="d-flex justify-content-between">
                      <b>
                        {rupees(val.price)}</b>
                      <div>
                        {val.quantity && val.quantity > 0 ? (
                          <div>
                            <button className="btn btn-danger btn-sm" onClick={() => AddtoCart(val.quantity - 1, val.id)}>–</button>
                            <button className="btn btn-outline-secondary btn-sm mx-1" disabled>{val.quantity}</button>
                            <button className="btn btn-success btn-sm" onClick={() => AddtoCart(val.quantity + 1, val.id)}>+</button>
                          </div>
                        ) : (

                          <button className="btn btn-success" onClick={() => AddtoCart(1, val.id)}>Add</button>
                        )}
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            ))}

          </div>
          <div className="col-sm-6">
            {state.sss && (
              <div className="border-top border-bottom my-4 py-3">
                <h4>Promo Code</h4>
                {state.discount > 0 ? (
                  <div className="couponbox mb-3 position-relative" id="couponadded">
                    <input type="text" className="form-control form-control-lg" value={state.coupon} placeholder="Enter Promo Code" disabled />
                    <button className="btn btn-lg button text-danger" type="button" onClick={() => RemoveCoupon()}>Remove</button>
                  </div>

                ) : (
                  <div className="couponbox mb-3 position-relative" id="addcoupon">
                    <input type="text" className="form-control form-control-lg" value={state.couponcode} onChange={(e) => setState({ ...state, couponcode: e.target.value })} placeholder="Enter Promo Code" />
                    <button className="btn btn-lg button text-success" type="button" disabled={state.couponcode && state.couponcode.length > 0 ? false : true} onClick={() => ApplyCoupon()}>Apply</button>
                  </div>

                )}
              </div>
            )}



            <div className="box">

              <div className="">
                <div className="d-flex justify-content-between">
                  <span className="text-muted">Sub Total</span>

                  <span>
                    {rupees(state.subtotal)}
                  </span>
                </div>



                {state.discount > 0 && (
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Coupon Discount</span>

                    <span className="text-success">
                      - {rupees(state.discount)}
                    </span>
                  </div>
                )}

                <div className="d-flex justify-content-between">
                  <span className="text-muted">Delivery Charges</span>


                  {state.shipping === 0 ? (
                    <span className="text-success">FREE</span>
                  ) : (<span >
                    {rupees(state.shipping)}</span>
                  )}

                </div>
                <div className="d-flex justify-content-between">
                  <b className="text-muted">Total Amount</b>

                  <span className="fw-bold">
                    {rupees(state.grand_total)}
                  </span>

                </div>
              </div>
            </div>
            <Link className="btn btn-success btn-lg w-100 mt-4" to="/checkout-address">Checkout</Link>
          </div>
        </div>
        </>
      ) : state.cart_itms && state.cart_itms.length < 1 ? (

        <div className="text-center p-4">
          <div className="emptycart m-auto">
            <FaCartArrowDown />
          </div>
          <p className="mt-4">Cart is empty</p>

        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}
