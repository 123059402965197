import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { LoadingScreen, UiInput, UiButton } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import zustand from "zustand";
import { Helmet } from "react-helmet";

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';


export default function Address() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  let { id } = useParams();

  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value


  const schema = yup.object().shape(
    {
      name: yup.string().required("Name is required"),
      address: yup.string().required("Address is required"),
      lat: yup.string().required("Please turn on location permission in your device!"),
      lng: yup.string().required("required"),
      mobile: yup
        .string().nullable().matches(/^[0-9]+$/, "Mobile number is required")
        .min(10, "Enter mobile number (10 digit)")

        .max(10, "Enter mobile number (10 digit)"),




      email: yup
        .string()
        .nullable()
        .when("email", {
          is: (value) => value?.length,
          then: (rule) =>
            rule
              .email(
                "Enter valid email"
              ),
        }),
    },
    [["email", "email"]]
  );

  var SaveAddress = () => {
    var form = document.querySelector("#addressform1");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_address",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.status == 'success') {
          toast('Saved', { type: "success" });
          navigate(-1);
        } else {

          toast('Please fill all required fields!', { type: "error" });
        }
      })
      .catch(function (response) { });
  };




  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveAddress();
    //reset();
  };
  const fetchData = () => {
    instance
      .get("/get_address?id=" + id)
      .then(function (response) {

        setState({ ...state, ...response.data });
        reset(response.data.address)
        if (id !== 'new'){ 
        setLocation({ lat: Number(response.data.address.lat), lng: Number(response.data.address.lng) })
        }
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';



  useEffect(() => {
    setTitle('Address')
    fetchData()
  }, []);


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBJLU_F9xy9NUoTWMSKyF1LSvsWqDr3RSQ"
  })

  const [mapref, setMapRef] = React.useState(null);
  const handleOnLoad = map => {
    setMapRef(map);
  };
  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();

      reset({ ...getValues, lat: newCenter.lat(), lng: newCenter.lng() })
    }
  };

  const [defaultLocation, setLocation] = React.useState({ lat: 25.34582677875356, lng: 72.62559978554263 });



  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      toast('Please turn on location permission in your device!', { type: "error" });
    }
  }

  function showPosition(position) {
    //setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
    reset({ ...getValues, lat: position.coords.latitude, lng: position.coords.longitude })
  }
  useEffect(() => {
  if(id == 'new'){
    getLocation() 
  } else {
    
  }
  }, [id]);


  return (
    <div>
      <Helmet>
        <title>Address</title>
      </Helmet>
      {state.status ? (
        <div className="mt-3">


          <form id="addressform1" onSubmit={handleSubmit(onSubmitHandler)}>
            <UiInput

              label="Full Name"
              type="text"
              name="name"
              message={errors.name?.message}
              {...register("name")}
            />
            <UiInput

              label="Full Address"
              type="text"
              name="address"
              message={errors.address?.message}
              {...register("address")}
            />
            <div className="row">
              <div className="col-6">
                <UiInput

                  label="City"
                  type="text"
                  name="address"
                  value="Jalore"
                  disabled
                />
              </div>
              <div className="col-6">
                <UiInput

                  label="Mobile No."
                  type="number"
                  name="mobile"
                  message={errors.mobile?.message}
                  {...register("mobile")}
                />
              </div>
            </div>

            <UiInput

              label="Email (optional)"
              type="text"
              name="email"
              message={errors.email?.message}
              {...register("email")}
            />
            <input type="hidden" name="id"  {...register("id")} />
            <input type="hidden" name="lat"  {...register("lat")} />
            <input type="hidden" name="lng"  {...register("lng")} />

           

            <small className="text-danger">{errors.lat?.message}</small>
            <UiButton class="btn btn-success btn-lg w-100 mt-4" title="Save" />
          </form>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}
