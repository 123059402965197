import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";

import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { Helmet } from "react-helmet";


export default function OrderSuccess() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value









  const fetchData = () => {
    instance
      .get("/payment?list=1")
      .then(function (response) {

        setState({ ...state, ...response.data });
        if (response.data.error ) {
          toast('Something went wrong!', { type: "error" });
          navigate('/cart');
        }
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';



  useEffect(() => {
    setTitle('Order Placed')
    //fetchData()
  }, []);
  return (
    <div>
   <Helmet>
        <title>Order Placed</title>
      </Helmet>
        <>

          <div class="pt-2 text-center">
         
  
              <div >
                <img src={`${homeurl}/imgs/scooter.svg`} className="w-100"/>
              <h2 className="fs-1">Order Successfully Placed.</h2>
              <h3 className="text-muted fs-3">Thank You</h3>
              <h4 className="fw-light">Your order will be delivered shortly</h4>

              </div>

              <div class="bottom-strip">
                <button class="btn btn-success btn-lg mt-4 w-100" onClick={()=>navigate("/")} >Done</button>
              </div>
            
          </div>
        </>
  
    </div>
  );
}
