import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import "bootstrap/dist/css/bootstrap.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UiButton, UiInput } from "../../ui";
import { LoginUser, setStorage } from "../../helper";
import { useStore } from "../../state.js";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { BsWhatsapp } from "react-icons/bs";

const Login = ({ ...props }) => {
  const { auth, setAuth } = useStore((state) => state);
  let navigat = useNavigate();
  const [loader, showLoader] = useState(false);

  const [seconds, setSeconds] = useState(null);

  const schema = yup.object().shape({
    login: yup.string(),
    mobile: yup.string().required("Mobile no. is required").matches(/^[0-9]+$/, "Enter 10 digit mobile number")
      .min(10, "Enter 10 digit mobile number")

      .max(10, "Enter 10 digit mobile number"),

    otp: yup.string().nullable()
      .when("login", {
        is: '1',
        then: (rule) =>
          rule
            .matches(/^[0-9]+$/, "Enter OTP")
            .min(1, "Enter 4 digit OTP")

            .max(4, "Enter 4 digit OTP"),
      }),


  });

  const [state, setState] = useState({});
  var doLogin = (e) => {
    showLoader(true)
    var form = document.querySelector("#loginform");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/login",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data })

        if (response.data.login == false) {
          toast("Incorrect OTP", { type: "error" });
        }
        if (response.data.otpsuccess == true) {
          setSeconds(30)
          resetField("login", { defaultValue: '1' })
        }
        if (response.data.login == true) {
          setAuth('1');
          setStorage("token", response.data.token);


          navigat("/", { replace: true });
        }
      })
      .catch(function (response) { });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    doLogin();
    //reset();
  };




  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(null);
    }
  });






  var resendOTP = (e) => {

    var data = new FormData();
    data.append('mobileno', state.mobile)
    showLoader(true);
    instance({
      method: "post",
      url: "/resend_otp",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader(false);
        if (response.data.status === "success") {
          setSeconds(30)
          toast("OTP resent successfully", { type: "success" });

        } else {
          window.location.reload();
        }
      })
      .catch(function (response) { });
  };






  return (
    <div className="container py-5 h-100">
      <Helmet>
        <title>Login</title>
      </Helmet>
      {loader && (
        <div className="overlay">
          <div className="overlay__inner">
            <div className="overlay__content"><span className="spinner"></span></div>
          </div>
        </div>
      )}
      <div className="row d-flex justify-content-center align-items-center h-100 pt-5 mt-5">

        <div className="col-12 col-md-8 col-lg-6 col-xl-5 px-4">
          <img src="https://jalorefooddelivery.com/imgs/logo.svg" className="m-auto mb-4 d-block" style={{ width: "70px" }}></img>
          {state.otpsuccess ? (
            <>
              <h3 className="mb-2">Enter OTP</h3>
              <p>Please enter OTP sent on {state.mobile}. <a class="text-primary" onClick={() => setState({ ...state, otpsuccess: null })}>Edit</a></p>
            </>
          ) : (<>
            <h3 className="mb-2">Login / Sign Up</h3>
            <p>Please enter your mobile number to Login or Sign Up</p>
          </>)}
          <form id="loginform" onSubmit={handleSubmit(onSubmitHandler)}>
            <div className={state.otpsuccess && 'd-none'}>
              <UiInput
                label="Mobile No."
                type="number"
                name="mobile"
                parentClass="mb-1"
                message={errors.mobile?.message}
                {...register("mobile")}
              />
              <small className="d-block mt-1 mb-4">Recommendation: Please enter your <span className="text-success align-text-bottom"><BsWhatsapp /></span> WhatsApp Number</small>
            </div>
            
            {state.otpsuccess && (
              <>
                <UiInput
                  label="OTP"
                  type="number"
                  name="otp"

                  message={errors.otp?.message}
                  {...register("otp")}
                />
                <div className="text-center mt-3">
                  {seconds > 0 ? (
                    <p className="text-sm" >
                      Resend OTP in <span>{seconds}</span> seconds
                    </p>
                  ) : (
                    <a className="btn btn-link m-auto" onClick={resendOTP}>
                      Resend OTP
                    </a>
                  )}


                </div>
              </>
            )}
            <input type="hidden" name="login" {...register("login")} />
            <UiButton className="w-100 btn btn-lg btn-success" title={state.otpsuccess ? 'Verify' : 'Get OTP'} />
          </form>

        </div>
      </div>
    </div>
  );
};

export default Login;
