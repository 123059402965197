import axios from "axios";
import { getStorage, setStorage, Logout } from "./helper";
import { useNavigate,Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useStore } from "./state.js";
import zustand from "zustand";
export const instance = axios.create({
  baseURL: "https://api.jalorefooddelivery.com",
  //timeout: 1000,
});

instance.interceptors.request.use(
  function (config) {
    if (getStorage("token")) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + getStorage("token"),
      };
    }

    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  function (response) {
    if(response.data.cart_count){
      useStore.setState({ cart_count: response.data.cart_count });
    }
    return response;
    
  },
  async function (error) {
    const originalRequest = error.config;
    //let navigat = useNavigate();
    if (error.response.status === 401 && !originalRequest._retry) {
     Logout()


      originalRequest._retry = true;
      isRefreshing = true;

  
    }
    if(error.toJSON().message === 'Network Error'){
      toast("Looks like you're offline!", { type: "info", theme:"light" });
  }

    return Promise.reject(error);
  }
);
