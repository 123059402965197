import React, { useState, useEffect } from "react";

import { useOutletContext, Link } from "react-router-dom";
import { instance } from "../../axios";
import {Helmet} from "react-helmet";
import { LoadingScreen } from "../../ui";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function Categories() {
  const homeurl = 'https://jalorefooddelivery.com';
  const [state, setState] = useState({});
  const { setTitle } = useOutletContext(); // <-- access context value


  useEffect(() => {
    setTitle("All Categories")
    instance
      .get("/categories")
      .then(function (response) {
        setState({ ...state, ...response.data })
      })
      .catch(function (error) { })
      .then(function () { });

  }, []);

  return (
    <div>
      <Helmet>
        <title>All Categories</title>
      </Helmet>
      {state.category ? (
      <div className="row mt-4">
        {state.category && state.category.map((val, i) => (
          <div className="col-4 col-sm-2 text-center mb-3" key={i}>
            <Link className="text-dark" to={`/category/${val.url}`}>
              <div className="catimg">
                <LazyLoadImage effect="blur" src={homeurl + '/imgs/category/m/' + val.image}/>
              </div>
              {val.title}
            </Link>
          </div>
        ))}
      </div>
      ) : (
<LoadingScreen/>
      )}
    </div>
  );
}
