import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { LoadingScreen } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Product() {
  let { id } = useParams();
  let navigate = useNavigate();
  const homeurl = 'https://jalorefooddelivery.com';
  const queryClient = useQueryClient()
  const [state, setState] = useState({});


  const { setTitle } = useOutletContext(); // <-- access context value




  const fetchData = () => {
    instance
      .get("/product?id=" + id)
      .then(function (response) {
        if (response.data.status == 'success') {
          setState({ ...state, ...response.data });
          setTitle(response.data.product.title)
        } else {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    fetchData();

    //reset({ step: { 5: '7' } })
  }, [id]);

  var AddtoCart = (qty, id) => {

    var data = new FormData();
    data.append('quantity', qty)
    data.append('id', id)
    instance({
      method: "post",
      url: "/cart?action=add",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          //toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {

          setState({ ...state, product: { ...state.product, quantity: qty } })
          if (navigator.vibrate) {
            navigator.vibrate(200);
          }
          // toast('Added to cart', { type: "success" });
        }
      })
      .catch(function (response) { });
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1
  };

  return (
    <div>
      {state.product ? (
        
        <>
           <Helmet>
        <title>{state.product.title}</title>
      </Helmet>
          <div className="row">
            <div className="col-sm-6 mb-4">
              <Slider {...settings}>


                {state.images && state.images.map((val, i) => (
                  <div className="ratio ratio-1x1" key={i}>
                    <LazyLoadImage effect="blur" src={homeurl + '/imgs/product/l/' + val.image} class="d-block w-100" />
                  </div>

                ))}
              </Slider>
            </div>
            <div className="col-sm-6">
              <div className="bg-white  p-3 rounded mb-4">
                <h2 className="fs-5 text-dark">{state.product.title}</h2>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                  <b className="d-block">{rupees(state.product.price)}</b>
                  {state.product.freeshipping && (
                  <small className="text-success fw-light">Free Delivery</small>
                  )}
                  </div>
                  <div>
                    {state.product.quantity && state.product.quantity > 0 ? (
                      <div>
                        <button className="btn btn-danger btn-sm" onClick={() => AddtoCart(state.product.quantity - 1, state.product.id)}>–</button>
                        <button className="btn btn-outline-secondary btn-sm mx-1" disabled>{state.product.quantity}</button>
                        <button className="btn btn-success btn-sm" onClick={() => AddtoCart(state.product.quantity + 1, state.product.id)}>+</button>
                      </div>
                    ) : (

                      <button className="btn btn-success" onClick={() => AddtoCart(1, state.product.id)}>Add</button>
                    )}
                  </div>
                </div>
              </div>

              {state.product.description && (
                <div className="bg-white p-3 rounded">
                  <h3>Product Details</h3>
                  <div className="fw-light">
                  {state.product.description}
                  </div>
              
                  </div>
                
              )}
            </div>
          </div>







        </>
      ):(
        <LoadingScreen/>
      )}
    </div>
  );
}
