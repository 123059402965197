import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { LoadingScreen } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import {
  IoPricetagsOutline,
  IoChevronForwardOutline,
  IoCheckmarkCircle,
} from "react-icons/io5";
import { FaCartArrowDown } from "react-icons/fa";
import { MdOutlineMoreVert } from "react-icons/md";


export default function MyAddress() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value





  const fetchData = () => {
    instance
      .get("/address_list")
      .then(function (response) {

        setState({ ...state, ...response.data });

      })
      .catch(function (error) { })
      .then(function () { });

  }


  const deleteAddress = (id) => {
    instance
      .get("/delete_address?id=" + id)
      .then(function (response) {

        if (response.data.status == 'success') {
          toast('Deleted', { type: "success" });

        } else {

          toast('Something went wrong!', { type: "error" });
        }
        fetchData()

      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';



  useEffect(() => {
    setTitle('My Addresses')
    fetchData()
  }, []);
  return (
    <div>
      <Helmet>
        <title>My Addresses</title>
      </Helmet>
      {state.address ? (
        <>

          <div className="pt-2">


            <div >
              <Link to="/address/new" className="mb-3 btn btn-secondary w-100">Add New Address</Link>
              {state.address.map((adata, index) => {
                return <div className="bg-white p-3 mb-2 rounded " key={index}>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <b>{adata.name}</b><br></br>
                      {adata.address}, {adata.city}<br></br>
                      {adata.mobile}


                    </div>
                    <div className="dropdown">
                      <button className="btn btn-default fs-3 text-muted" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <MdOutlineMoreVert />
                      </button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" onClick={() => navigate("/address/" + adata.id)}>Edit</a></li>
                        <li><a className="dropdown-item" onClick={() => deleteAddress(adata.id)}>Delete</a></li>
                      </ul>
                    </div>
                  </div>



                </div>;
              })}
            </div>

          </div>
        </>
      ):(
        <LoadingScreen/>
      )}
    </div>
  );
}
