import React, { forwardRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select, { StylesConfig } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import DatePicker from "react-datepicker";
import { instance } from "./axios";
import { format, isValid, parse } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from 'react-quill';
import { GoPlus } from "react-icons/go";
import AsyncSelect from 'react-select/async';


import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { useDropzone } from 'react-dropzone';


import { BsImageFill } from "react-icons/bs";
import { MdEdit, MdClose } from "react-icons/md";


import 'react-quill/dist/quill.snow.css';

export function UiModal(props) {
  const { show, onHide, heading, ...other } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body {...other} />
    </Modal>
  );
}

export const UiInput = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      id,
      placeholder,
      className,
      lcol,
      icol,
      message,
      prefix,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`form-floating ${parentClass ? { parentClass } : "mb-4 "} ${lcol ? "row" : ""}`}>
       
     
          {prefix && (<span className="input-group-text">{prefix}</span>)}
          <input
            name={name}
            ref={ref}
            type={type}
            id={id}
            className={`form-control form-control-lg form-control-solid ${message ? "is-invalid" : ""
              } ${className}`}
            placeholder={label}
            autoComplete="off"
            {...props}
          />
           {label && (
          <label htmlFor={id}
            className={`form-label fs-6 text-muted ${lcol ? "col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red-500 font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
     
      </div>
    );
  }
);

export const UiButton = forwardRef(
  ({ type, name, title, className, loading, disabled, ...props }, ref) => {
    return (
      <div>
        <button
          name={name}
          ref={ref}
          type={type}
          className={`btn btn-primary ${className}`}
          {...props}
          disabled={((loading) || (disabled == 1)) ? "disabled" : ""}
        >
          {loading ? (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <span className="indicator-label">{title}</span>
          )}
        </button>
      </div>
    );
  }
);

export const UiToggle = forwardRef(({ id, name, pclass, loading, title, ...props }, ref) => {
  return (
    <>
      {loading ? (
        <span className="spinner-border spinner-border-sm"></span>
      ) : (

        <div className={`form-check form-switch ${pclass ? '' : 'mb-4'}`}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            name={name}
            ref={ref}
            id={id}
            {...props}
          />
          <label className="form-check-label" htmlFor={id}>
            {title}
          </label>
        </div>

      )}
    </>
  );
});

export const UiModal1 = (props) => {
  const { kind, ...other } = props;
  const className = kind === "primary" ? "PrimaryButton" : "SecondaryButton";
  return <b className={className} {...other} />;
};



export const UiRSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      addnew,
      handleNew,
      ...props
    },
    ref
  ) => {
    const MenuList = (
      props
    ) => {
      return (
        <components.MenuList {...props}>

          {props.children}
          <div className="p-2">
            <button className="btn btn-outline-secondary w-100" onClick={() => handleNew()}><span className="sicon"><GoPlus /></span> Add New</button>
          </div>
        </components.MenuList>
      );
    };
    return (

      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
          <Select
            ref={ref}
            components={addnew && { MenuList }}
            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}
          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);



export const UiTagInput = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
          <CreatableSelect
            isClearable
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            isMulti
            noOptionsMessage={() => null}
            ref={ref}

            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);


export const UiSelect = forwardRef(({ icol, className, message, parentClass, lcol, options, label, required, default_val, name, noerror, title, ...props }, ref) => {
  return (
    <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
      {label && (
        <label
          className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red-500 font-bold text-lg">
            {required && "*"}
          </span>
        </label>
      )}
      <div className={icol ? "col-sm-" + icol : ""}>
        <select
          className={`form-select form-select-lg form-select-solid ${message ? "is-invalid" : ""
            } ${className}`}
          name={name}
          ref={ref}
          {...props}>
          <option></option>
          {options[0].value ? options.map((option, i) => (
            <option value={option.value} key={i}>{option.label}</option>
          )) :
            options.map((option, i) => (
              <option value={option} key={i}>{option}</option>
            ))
          }
        </select>
        {!noerror && message && <div className="invalid-feedback">{message}</div>}

      </div>
    </div>
  );
});


export function UiTFind(props) {
  const { setFilterText, filterText, heading } = props;
  return (
    <div className="input-group flex-nowrap width25">
      <span className="input-group-text" ><AiOutlineSearch /></span>
      {filterText && (<span className="clearbtn" onClick={() => setFilterText('')}><MdOutlineClose /></span>)}
      <input className="form-control" onChange={e => setFilterText(e.target.value)} value={filterText} />

    </div>
  );
}



export function UiStatusChange(props) {
  const { status, onUpdate, loading } = props;
  const sts = ['Pending','In-Progress','Completed'];
  return (
    <div className="btn-group">
      <button type="button" className={`btn ${status == 'Pending' ? 'btn-danger' : status == 'Completed' ? 'btn-success' : 'btn-primary'}`} >{loading == true ? <span className="indicator-progress" >
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span> : status}</button>
      <button type="button" className={`btn dropdown-toggle dropdown-toggle-split ${status == 'Pending' ? 'btn-danger' : status == 'Completed' ? 'btn-success' : 'btn-primary'}`}  data-bs-toggle="dropdown" aria-expanded="false" >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu">
        {sts.map((val,i)=>(
          <li key={i}><a className={`dropdown-item ${val==status && 'disabled'} ${loading==true && 'disabled'}`} onClick={()=>onUpdate(val)}>{val}</a></li>
        ))}


      </ul>
    </div>
  );
}





export const UiDatePicker = forwardRef(({ icol, className, dateFormat, message, lcol, label, required, default_val, name, onChange, title, ...props }, ref) => {
  return (
    <div className={`mb-4 ${lcol ? "row" : ""}`}>
      <label
        className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
          }`}
      >
        {label}{" "}
        <span className="text-red-500 font-bold text-lg">
          {required && "*"}
        </span>
      </label>
      <div className={icol ? "col-sm-" + icol : ""}>

        <DatePicker
          className={`form-control form-control-lg form-control-solid ${message ? "is-invalid" : ""
            } ${className}`}
          onChange={(e) => onChange(format(e, dateFormat))} // send value to hook form
          selected={default_val && parse(default_val, dateFormat, new Date())}
          name={name}
          dateFormat={dateFormat}
          ref={ref}
          {...props}
        />
        {message && <div className="invalid-feedback d-block">{message}</div>}

      </div>
    </div>
  );
});


export const UiRichText = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    const [state, setState] = useState('');

    const handleOnChange = (e) => {
      if (e != '<p><br></p>') {
        props.onChange(e);
        //setState(e);
      } else {
        // setState('');
        props.onChange('');
      }
    }

    useEffect(() => {
      if (props.value) {
        setState(props.value)
      } else {
        setState('')
      }
    }, [props.value]);


    return (
      <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red-500 font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <ReactQuill theme="snow"
            ref={ref} className={`${message ? "is-invalid" : ""
              } ${className}`} value={props.value} onChange={(e) => handleOnChange(e)} />
          <input type="hidden" name={name} value={state} onChange={setState} />
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);



export const UiTextArea = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red-500 font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <textarea
            name={name}
            ref={ref}
            type={type}
            className={`form-control form-control-lg form-control-solid ${message ? "is-invalid" : ""
              } ${className}`}
            placeholder={placeholder}
            autoComplete="off"
            {...props}
          />
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);



export const UiImageUpload = forwardRef(
  (
    {
      file,
      name,
      ratio,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      setFile,
      width,
      height,
      message,
      onClear,
      ...props
    },
    ref
  ) => {
    const [files, setFiles] = useState([]);
    const [editfile, setEditFile] = useState([]);

    const { getRootProps, getInputProps, open } = useDropzone({
      accept: {
        'image/*': []
      },
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));

      }
    });
    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    const userModal = useModal('crop');

    const showAddModal = (row) => {
       userModal.show({ data: row, width1: width, height1: height }).then((res) => {
     

        var toblob = new File([res.cropped.blob1],'a.png', {type: "image/png"});
        
        var bodyFormData = new FormData();
       // bodyFormData.append('data', res.cropped.data);
        bodyFormData.append("file", toblob);


        instance({
          method: "post",
          url: "/image_upload",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {

            if (response.data.status == "success") {
              setEditFile(response.data.file)
              setFile(response.data.file)
            }
          })
          .catch(function (response) { });
      });

      window.history.pushState("", "", '#crop');

    }

    const handleClear = () => {
      onClear(null)
      setEditFile(null)
    }


    useEffect(() => {
      if (files && files.length > 0) {
        showAddModal(files[0].preview, width, height);
      }
    }, [files]);




    return (
      <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label fs-6 fw-bolder text-dark ${lcol ? "col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red-500 font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <div className="position-relative w-25">
            <div className={`p-2 border rounded bg-light ratio ${ratio}`}>
              {file ? <img src={file} /> : (<div className="placeholderimg"><BsImageFill /></div>)}

            </div>
            <div className="text-center">
              <div className="btn btn-outline-secondary imagebtn" onClick={open}><MdEdit /></div>
              {file && <div className="btn btn-outline-secondary imagebtn" onClick={handleClear}><MdClose /></div>}
            </div>
          </div>
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
        </div>
        {editfile && <input type="hidden" value={editfile} name={name} />}
      </div>
    );
  }
);



export const LoadingScreen = forwardRef(
  (
    {


    },
    ref
  ) => {
    return (
     
       <div className="placeholder-glow w-100 h-100 position-fixed top-0 start-0">
    <div className="w-100 h-100 placeholder" style={{backgroundColor:'rgb(171 171 171)'}}></div>
  </div>
      
    );
  }
);