import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { Logout } from "../../helper";
import { LoadingScreen } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { Helmet } from "react-helmet";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineArrowForwardIos, MdOutlineMap } from "react-icons/md";


export default function UserProfile() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value


  const profilestyle = {
    ordericon: {
      'color': '#54b175',
      'background-color': '#e4f3ea',


    },
    addressicon: {
      'color': '#03b0a9',
      'background-color': '#ddf5f4',


    },
    logouticon: {
      'color': '#fe6e4c',
      'background-color': '#ffece8',


    }
  }






  const fetchData = () => {
    instance
      .get("/profile")
      .then(function (response) {

        setState({ ...state, ...response.data });
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';



  useEffect(() => {
    setTitle('User Profile')
    fetchData()
  }, []);
  return (
    <div>
         <Helmet>
        <title>Profile</title>
      </Helmet>
      {state.customer ? (
        <>

          <div class="pt-2 text-center pb-4 mb-4">

            <div className="text-muted" style={{ 'font-size': '100px' }}><FaRegUserCircle /></div>
            <div >
              <b>{state.customer.name}</b>


            </div>



          </div>


          <div className="w-100  bg-white rounded mb-3">
            <Link className="p-2 d-flex align-items-center" to="/my-orders">
              <div className="fs-3 me-3 profileicon" style={profilestyle.ordericon}><FaRegUserCircle /></div>
              <span className="flex-grow-1 text-dark fw-bold">My Order</span>
              <div className="fs-3 text-dark"><MdOutlineArrowForwardIos /></div>
            </Link>
          </div>

          <div className="w-100  bg-white rounded mb-3">
            <Link className="p-2 d-flex align-items-center" to="/my-addresses">
              <div className="fs-3 me-3 profileicon" style={profilestyle.addressicon}><MdOutlineMap /></div>
              <span className="flex-grow-1 text-dark fw-bold">My Address</span>
              <div className="fs-3 text-dark"><MdOutlineArrowForwardIos /></div>
            </Link>
          </div>

          <div className="w-100  bg-white rounded mb-3">
            <a className="p-2 d-flex align-items-center" onClick={() => Logout()}>
              <div className="fs-3 me-3 profileicon" style={profilestyle.logouticon}><MdOutlineArrowForwardIos /></div>
              <span className="flex-grow-1 text-dark fw-bold">Log Out</span>
            </a>
          </div>
        </>

      ):(
        <LoadingScreen/>
      )}
    </div>
  );
}
