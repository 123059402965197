import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { LoadingScreen, UiInput, UiButton } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Helmet } from "react-helmet";
import {
  IoPricetagsOutline,
  IoChevronForwardOutline,
  IoCheckmarkCircle,
} from "react-icons/io5";
import { FaMapPin } from "react-icons/fa";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';


export default function CheckoutAddress() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value


  const schema = yup.object().shape(
    {
      name: yup.string().required("Name is required"),
      address: yup.string().required("Address is required"),
      lat: yup.string().required("Please turn on location permission in your device!"),
      lng: yup.string().required("required"),

      mobile: yup
        .string().nullable().matches(/^[0-9]+$/, "Mobile number is required")
        .min(10, "Enter mobile number (10 digit)")

        .max(10, "Enter mobile number (10 digit)"),




      email: yup
        .string()
        .nullable()
        .when("email", {
          is: (value) => value?.length,
          then: (rule) =>
            rule
              .email(
                "Enter valid email"
              ),
        }),
    },
    [["email", "email"]]
  );

  var SelectAddress = () => {

    var data = new FormData();
    data.append('selected', state.selected)

    instance({
      method: "post",
      url: "/address?select",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.update) {
          navigate('/checkout-payment');
        } else if (response.data.error) {
          toast('Please select address!', { type: "error" });
        }

      })
      .catch(function (response) { });
  };

  var SaveAddress = () => {
    var form = document.querySelector("#addressform");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/address?add",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.update) {
          navigate('/checkout-payment');
        } else {

          toast('Please fill all required fields!', { type: "error" });
        }
      })
      .catch(function (response) { });
  };

  var onInput1 = (event) => {

    let val = event.target.value;
    setState({ ...state, selected: val });
  }


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    SaveAddress();
    //reset();
  };
  const fetchData = () => {
    instance
      .get("/address?list=1")
      .then(function (response) {

        setState({ ...state, ...response.data });
        if (response.data.addform == true) {
          getLocation()

        }
      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';


  const editAdd = (i) => {
    setState({ ...state, addform: true })
    setLocation({ lat: Number(state.address[i].lat), lng: Number(state.address[i].lng) })
    reset(state.address[i])
  }
  useEffect(() => {
    setTitle('Address')
    fetchData()
  }, []);


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBJLU_F9xy9NUoTWMSKyF1LSvsWqDr3RSQ"
  })

  const [mapref, setMapRef] = React.useState(null);
  const handleOnLoad = map => {
    setMapRef(map);
  };
  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();

      reset({ ...getValues, lat: newCenter.lat(), lng: newCenter.lng() })
    }
  };

  const [defaultLocation, setLocation] = React.useState({ lat: 25.34582677875356, lng: 72.62559978554263 });


  function showPosition(position) {
    
    // setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
     reset({ ...getValues, lat: position.coords.latitude, lng: position.coords.longitude })
   }
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
     
    } else {
      toast('Please turn on location permission in your device!', { type: "error" });
    }
  }

  



  const addNewAdd = () => {
    
    setState({ ...state, addform: true })
    getLocation()

  }

  return (
    <div className="mt-2">
      <Helmet>
        <title>Checkout Address</title>
      </Helmet>
      {state.addform == false ? (
        <>

          <div className="pt-2">

            <div className="container1">
              <button className="btn btn-primary mb-3 w-100" onClick={() => addNewAdd()}>Add new address</button>
            </div>
            <div >
              {state.address.map((adata, index) => {
                return <div className="bg-white p-3 mb-2 rounded " key={index}>
                  <div className="d-flex">
                    <input type="radio" className="me-3" id={adata.id} value={adata.id} onChange={onInput1} checked={state.selected == adata.id ? true : false}></input>
                    <div>
                      <label htmlFor={adata.id}> <b>{adata.name}</b><br></br>{adata.address}<br></br>{adata.city}<br></br>{adata.mobile}</label>
                      <a className="d-block" onClick={() => editAdd(index)}>Edit</a>
                    </div>
                  </div>



                </div>;
              })}
            </div>

            <div className="bottom-strip">
              <button className="btn btn-success btn-lg mt-4 w-100" onClick={() => SelectAddress()} type="submit" disabled={state.selected ? false : true}>Continue</button>
            </div>

          </div>
        </>
      ) : state.addform == true ? (

        <div>
          {state.address && state.address.length > 0 && (
            <button className="btn btn-outline-primary mb-3 w-100" onClick={() => setState({ ...state, addform: false })}>Go back to addresses</button>
          )}

          <form id="addressform" onSubmit={handleSubmit(onSubmitHandler)}>
            <UiInput

              label="Full Name"
              type="text"
              name="name"
              message={errors.name?.message}
              {...register("name")}
            />
            <UiInput

              label="Full Address"
              type="text"
              name="address"
              message={errors.address?.message}
              {...register("address")}
            />
            <div className="row">
              <div className="col-6">
                <UiInput

                  label="City"
                  type="text"
                  name="address"
                  value="Jalore"
                  disabled
                />
              </div>
              <div className="col-6">
                <UiInput

                  label="Mobile No."
                  type="number"
                  name="mobile"
                  message={errors.mobile?.message}
                  {...register("mobile")}
                />
              </div>
            </div>

            <UiInput

              label="Email (optional)"
              type="text"
              name="email"
              message={errors.email?.message}
              {...register("email")}
            />
            <input type="hidden" name="id"  {...register("id")} />
            <input type="hidden" name="lat"  {...register("lat")} />
            <input type="hidden" name="lng"  {...register("lng")} />


      
            <small className="text-danger">{errors.lat?.message}</small>

            <UiButton className="btn btn-success btn-lg w-100 mt-4" title="Save & Continue" />
          </form>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}
