import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Routes,
  Route,
  BrowserRouter,

} from "react-router-dom";
import App from "./App";
import NiceModal from '@ebay/nice-modal-react';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<QueryClientProvider client={queryClient}>
  <ToastContainer hideProgressBar={true} theme="colored"/>
 

  <BrowserRouter>
  <NiceModal.Provider>
<App/>
</NiceModal.Provider>
  </BrowserRouter>
  

  </QueryClientProvider>
);