import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { LoadingScreen } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { Helmet } from "react-helmet";
import { MdAccessTime } from "react-icons/md";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Order() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  let { id } = useParams();

  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value


const cancelOrder = () => {
  
    instance
      .get("/cancel_order?id=" + id)
      .then(function (response) {
        
        fetchData()

      })
      .catch(function (error) { })
      .then(function () { });

}



  const fetchData = () => {
    instance
      .get("/get_order?id=" + id)
      .then(function (response) {

        setState({ ...state, ...response.data });

      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';



  useEffect(() => {
    setTitle('Order #' + id)
    fetchData()
  }, []);
  return (
    <div className="mt-2">
   <Helmet>
        <title>Order</title>
      </Helmet>
      {state.status ? (
        <div>
          <div className="d-flex mb-2">
            <div className="text-muted flex-grow-1">
              <MdAccessTime /> {state.order.ordered_on}
            </div>
            <b className="text-uppercase">{state.order.status}</b>

          </div>
          {state.order.items.map((adata, index) => {
            return <div class="bg-white p-3 mb-2 rounded ">
              <Link class="d-flex align-items-center" to={`/product/${adata.url}`}>
              <div className="ratio ratio-1x1 me-3" style={{ width: '50px' }}>
                  <LazyLoadImage effect="blur" src={`${homeurl}/imgs/product/s/${adata.main_image}`} className="w-100"/>
                </div>
                <div className="flex-grow-1">
                  <p className="text-muted">{adata.title}</p>
                  <div className="d-flex">
                    <div className="flex-grow-1 text-dark">Qty. {adata.qty}</div>
                    <span className="text-end text-dark fw-bold">{rupees(adata.price * adata.qty)}</span>
                  </div>
                  



                </div>

              </Link>



            </div>;
          })}

          <div className="box mt-4">

            <div className="">
              <div className="d-flex justify-content-between mb-2">
                <span className="text-muted">Sub Total</span>

                <span>
                  {rupees(state.order.sub_total)}
                </span>
              </div>



              {state.order.discount > 0 && (
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Coupon Discount</span>

                  <span className="text-success">
                    - {rupees(state.order.discount)}
                  </span>
                </div>
              )}

              <div className="d-flex justify-content-between mb-2">
                <span className="text-muted">Delivery Charges</span>


                {state.order.shipping === 0 ? (
                  <span className="text-success">FREE</span>
                ) : (<span >
                  {rupees(state.order.shipping)}</span>
                )}

              </div>
              <div className="d-flex justify-content-between mb-2">
                <b className="text-muted">Total Amount</b>

                <span className="fw-bold">
                  {rupees(state.order.total)}
                </span>

              </div>
            </div>
                  {state.order.status == 'ordered' && 
            <button className="btn btn-outline-danger mt-4 w-100" onClick={()=>cancelOrder()}>Cancel Order</button>}
          </div>

        </div>
      ):(
        <LoadingScreen/>
      )}
    </div>
  );
}
