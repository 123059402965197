import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useStore } from "./state.js";
import { getStorage } from "./helper";

import {
  Routes,
  Route,
  BrowserRouter,
  Link,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import Login from "./routes/login";
import Home from "./routes/home";
import Layout from "./layout";



import Category from "./routes/category";
import Product from "./routes/product";
import Cart from "./routes/cart";
import CheckoutAddress from "./routes/checkout_address";
import CheckoutPayment from "./routes/checkout_payment";
import OrderSuccess from "./routes/order_success";
import UserProfile from "./routes/user_profile";
import MyAddress from "./routes/my_address";
import Address from "./routes/address";
import MyOrders from "./routes/my_orders";
import Order from "./routes/order";
import Categories from "./routes/categories";
import './styles.css';
import NiceModal, { ModalDef, useModal } from '@ebay/nice-modal-react';


if (getStorage('token')) {
  useStore.setState({ auth: 1 });
  //setAuth('1');

}
function AuthRoute({ children }) {
  const auth = useStore((state) => state.auth)
  let location = useLocation();

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}


function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}





export default function App() {

  useEffect(() => {
    window.addEventListener('hashchange', (event) => {
      //console.log(event.oldURL.split("#")[1])
      var mid = event.oldURL.split("#")[1];
      if (mid) {
        NiceModal.hide(event.oldURL.split("#")[1])
      }
      if (window.location.hash) {
        NiceModal.show(window.location.hash.split("#")[1])
      }
    });


  }, []);
  const location = useLocation();
  useEffect(() => {


  }, [location]);
  return (
    <>
      <Routes>

        <Route path="/" element={<Layout />}  >
          <Route path="/" element={<AuthRoute><Home /></AuthRoute>} />
          <Route path="category/:id" title="dd" element={<AuthRoute><Category /></AuthRoute>} />
          <Route path="product/:id" title="dd" element={<AuthRoute><Product /></AuthRoute>} />
          <Route path="cart"  element={<AuthRoute><Cart /></AuthRoute>} />
          <Route path="categories"  element={<AuthRoute><Categories /></AuthRoute>} />
          <Route path="checkout-address"  element={<AuthRoute><CheckoutAddress /></AuthRoute>} />
          <Route path="checkout-payment"  element={<AuthRoute><CheckoutPayment /></AuthRoute>} />
          <Route path="user-profile"  element={<AuthRoute><UserProfile /></AuthRoute>} />
          <Route path="my-addresses"  element={<AuthRoute><MyAddress /></AuthRoute>} />
          <Route path="my-orders"  element={<AuthRoute><MyOrders /></AuthRoute>} />
          <Route path="order-success/:id"  element={<AuthRoute><OrderSuccess /></AuthRoute>} />
          <Route path="address/:id"  element={<AuthRoute><Address /></AuthRoute>} />
          <Route path="order/:id"  element={<AuthRoute><Order /></AuthRoute>} />
        </Route>
        <Route path="*" element={<NoMatch />} />
        <Route path="/login" element={<Login />} />
       

      </Routes>
    </>
  );
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
