import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import {  LoadingScreen } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function MyOrders() {
  let navigate = useNavigate();
  const queryClient = useQueryClient()


  const [state, setState] = useState({});
  const [loader, showLoader] = useState({});

  const { setTitle } = useOutletContext(); // <-- access context value





  const fetchData = () => {
    instance
      .get("/my_orders")
      .then(function (response) {

        setState({ ...state, ...response.data });

      })
      .catch(function (error) { })
      .then(function () { });

  }


  const deleteAddress = (id) => {
    instance
      .get("/delete_address?id=" + id)
      .then(function (response) {

        if (response.data.status == 'success') {
          toast('Deleted', { type: "success" });

        } else {

          toast('Something went wrong!', { type: "error" });
        }
        fetchData()

      })
      .catch(function (error) { })
      .then(function () { });

  }

  const homeurl = 'https://jalorefooddelivery.com';



  useEffect(() => {
    setTitle('My Orders')
    fetchData()
  }, []);
  return (
    <div>
      <Helmet>
        <title>My Orders</title>
      </Helmet>
      {state.orders ? (
        <>

          <div className="pt-2">


            <div >

              {state.orders.map((adata, index) => {
                return <div className="bg-white p-3 mb-2 rounded " key={index}>
                  <Link className="d-flex align-items-center text-dark" to={`/order/${adata.order_no}`}>
                  <div className="ratio ratio-1x1 me-3" style={{ width: '50px' }}>
                      <LazyLoadImage effect="blur" src={`${homeurl}/imgs/product/s/${adata.main_image}`} className="w-100"/>
                    </div>
                    <div className="flex-grow-1">
                      <span className="mb-2">{adata.title}</span><br></br>
                      <b>{rupees(adata.price)}</b>



                    </div>

                  </Link>



                </div>;
              })}
            </div>

          </div>
        </>
      ):(
        <LoadingScreen/>
      )}
    </div>
  );
}
