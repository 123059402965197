import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { LoadingScreen } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {Helmet} from "react-helmet";


export default function Category() {
  let { id } = useParams();
  let navigate = useNavigate();
  const homeurl = 'https://jalorefooddelivery.com';
  const queryClient = useQueryClient()
  const [state, setState] = useState({});


  const { setTitle } = useOutletContext(); // <-- access context value




  const fetchData = () => {
    instance
      .get("/category?id=" + id)
      .then(function (response) {
        if (response.data.status == 'success') {
          setState({ ...state, ...response.data });
          setTitle(response.data.title)
        } else {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    fetchData();

    //reset({ step: { 5: '7' } })
  }, [id]);

  var AddtoCart = (qty, id) => {

    var data = new FormData();
    data.append('quantity', qty)
    data.append('id', id)
    instance({
      method: "post",
      url: "/cart?action=add",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          //toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          var arr = state.product.slice();
          arr.filter(x => x.id == id)[0].quantity = qty;
          setState({ ...state, product: arr })
          if (navigator.vibrate) {
            navigator.vibrate(200);
          }
          // toast('Added to cart', { type: "success" });
        }
      })
      .catch(function (response) { });
  };


  return (
    <div className="mt-3">
{state.status ? (
  
      <>
      <Helmet>
        <title>{state.title}</title>
      </Helmet>
      <div className="row">
        {state.category && state.category.map((val, i) => (
          <div className="col-4 col-sm-2 mb-3 text-center text-dark" key={i}>
            <Link className="text-dark" to={`/category/${val.url}`}>
              <div className="catimg">
                <LazyLoadImage effect="blur" src={homeurl + '/imgs/category/m/' + val.image}/>
              </div>
              {val.title}
            </Link>
          </div>
        ))}
      </div>

      {state.product && state.product.map((val, i) => (
        <div className="p-2 bg-white rounded mb-2" key={i}>

          <div className="d-flex d-flex align-items-center">

            <div className="ratio ratio-1x1" style={{ width: '100px' }}>
              <Link to={`/product/${val.url}`}>
                <LazyLoadImage effect="blur" className="w-100 h-100 rounded" src={homeurl + '/imgs/product/s/' + val.main_image}/>
              </Link>
            </div>

            <div className="ms-3 w-100">
              <Link to={`/product/${val.url}`}>
                <h2 className="fs-5 text-dark mb-3">{val.title}</h2>
              </Link>
              <div className="d-flex justify-content-between">
                <div className="flex-grow-1">
                  <b className="d-block">{rupees(val.price)}</b>
                  {val.freeshipping && (
                    <small className="text-success fw-light">Free Delivery</small>
                  )}
                </div>
                <div>
                  {val.quantity && val.quantity > 0 ? (
                    <div>
                      <button className="btn btn-danger btn-sm" onClick={() => AddtoCart(val.quantity - 1, val.id)}>–</button>
                      <button className="btn btn-outline-secondary btn-sm mx-1" disabled>{val.quantity}</button>
                      <button className="btn btn-success btn-sm" onClick={() => AddtoCart(val.quantity + 1, val.id)}>+</button>
                    </div>
                  ) : (

                    <button className="btn btn-success" onClick={() => AddtoCart(1, val.id)}>Add</button>
                  )}
                </div>
              </div>
            </div>

          </div>

        </div>
      ))}

      {state.category == null && state.product == null && (
        <div className="text-center p-4">
    
        <p className="mt-4">Nothing found!</p>

      </div>
      )}

</>
):(
  <LoadingScreen/>
)}
    </div>
  );
}
